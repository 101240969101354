<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          stack-label
          v-model="jenjang"
          label="Jenjang"
        ></q-input>
        <q-input
          outlined
          stack-label
          v-model="nama_asrama"
          label="Nama Asrama"
        ></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions align="right">
        <q-btn
          flat
          outline
          color="primary"
          label="Cancel"
          @click="onCancelClick"
        />
        <q-btn
          unelevated
          color="primary"
          :disable="nama_asrama == ''"
          v-if="data.id < 0"
          @click="addAsrama"
          label="Tambah Asrama"
        ></q-btn>
        <q-btn
          unelevated
          color="primary"
          v-else
          @click="simpan"
          label="Simpan"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["data", "jenjang"],
  data() {
    return {
      nama_asrama: "",
    };
  },
  mounted() {
    if (this.data.id > 0) {
      this.nama_asrama = this.data.nama;
    }
  },
  methods: {
    simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let bundle = {
        nama_asrama: this.nama_asrama,
      };
      this.$http.put(`/pengasuhan/asrama/updatenama/${this.data.id}`, bundle);
      this.onOKClick();
      this.$q.loading.hide();
    },
    addAsrama() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http.post(
        `/pengasuhan/asrama/add/${this.nama_asrama}/${this.jenjang}`
      );
      this.$q.notify({
        message: `Asrama ${this.nama_asrama} berhasil di simpan`,
        color: "positive",
      });
      this.$q.loading.hide();
      this.onOKClick();
    },
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      this.$emit("ok");
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
