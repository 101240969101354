<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row q-gutter-sm">
        <q-select
          :options="$LIST_PENGASUHAN"
          v-model="selJenjang"
          @input="getListAsrama()"
          outlined
          label="jenjang"
          style="min-width: 130px;"
        ></q-select>
        <q-btn
          :disable="selJenjang == null"
          icon="add"
          color="primary"
          label="tambah asrama"
          @click="showDialog({ id: -1 })"
        ></q-btn>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>Nama Asrama</th>
            <th>jumlah kamar</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selJenjang == null">
            <td colspan="4" class="text-center">
              Silahkan Pilih Jenjang Terlebih Dahulu
            </td>
          </tr>
          <tr v-else-if="listAsrama.length == 0">
            <td colspan="4" class="text-center">
              Tidak Ada Asrama di Jenjang Terpilih
            </td>
          </tr>
          <tr v-for="(val, i) in listAsrama" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.jumlah_kamar }}</td>
            <td>
              <q-btn
                flat
                dense
                @click="showDialog(val)"
                icon="edit"
                color="secondary"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import DialogPengasuhanAsrama from "@/components/DialogPengasuhanAsrama";
export default {
  data() {
    return {
      selJenjang: null,
      selAsrama: "",
      listAsrama: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    showDialog(data) {
      this.$q
        .dialog({
          component: DialogPengasuhanAsrama,
          parent: this,
          data: data,
          jenjang: this.selJenjang,
        })
        .onOk(async (resp) => {
          this.getListAsrama();
        });
    },

    async getListAsrama() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/asrama/list/${this.selJenjang}`,
        this.$hlp.getHeader()
      );
      this.listAsrama = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
